import React from "react";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Section from "../../components/Section/Section";
import classNames from "classnames";
import fadeInSlide from 'utils/animations/fade-in-slide';

import "./GridCard.scss";

const GridCard = ({ items, callToAction }) => {
  // Check if the amount of Grid items can be divided by 3 to determine if call to action stands alone.
  const fullGrid = items?.length % 3 === 0;

  const [ tweenRef ] = fadeInSlide('x', 25, { animateChildren: true });

  return (
    <Section className="GridCard container">
      <div ref={tweenRef} className={classNames("row", 'alpha0-children', { "justify-content-center": fullGrid })}>
        {items?.map((item, index) => {
          const img = item.baseFields.previewImage.useImage().thumbnail.imgData;
          return (
            <div className="col-lg-4" key={index}>
              <Card
                uri={item.uri}
                title={item.title}
                description={item.baseFields.previewDescription}
                image={img}
              />
            </div>
          );
        })}
        <div className={fullGrid ? "col-lg-6 Text--center" : "col"}>
          <div className="CallToAction">
            {callToAction.title && <h5>{callToAction.title}</h5>}
            <div
              className="ActionText"
              dangerouslySetInnerHTML={{ __html: callToAction.description }}
            />
            {callToAction.buttonLink && (
              <Button
                to={new URL(callToAction.buttonLink.url).pathname}
                variant="dark"
                title={callToAction.buttonLink.title}
              >
                {callToAction.buttonLink.title}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GridCard;
